import { TOGGLE_SNACK } from '@/plugins/vuex/mutationTypes';

export const snackSuccess = (commit, message) => commit(TOGGLE_SNACK, {
  color: 'success',
  message,
}, { root: true });

export const snackError = (commit, message) => commit(TOGGLE_SNACK, {
  color: 'error',
  message,
}, { root: true });
